// Classes
import SessionService from './session';

// Utils
import { uniq } from 'lodash';
import { hasPermission, canAssignRole, matchesRoles, getOrgsByFeatureFlag } from 'libs/utils/permissions';
import { getNodeName, getBackstageURL } from 'libs/utils/url';
import { endOfToday, startOfToday } from 'libs/utils/time';
import { groupByStage } from 'libs/utils/workspaces';

// Constants
import {
    API_BASE_PATH,
    USER_SERVICE_ENDPOINT,
    USER_GET_ENDPOINT,
    USER_GET_ENDPOINT_ALL_EVENTS,
    USER_PROFILE_PATH,
    ROLE_SUPERADMIN,
    ROLE_ADMIN,
    ROLE_BETA_TESTER,
    ROLE_DEVELOPER,
    WEP_APP_LOGIN_URL_ENDPOINT
} from 'libs/utils/constants';

const USER_GET_ENDPOINT_ALL_WEBINARS = `${API_BASE_PATH}/webinars`;

/**
 * @const {string} TARGETING_AFFECTED_USERS_ENDPOINT the endpoint used to start getting the count/list of affected users
 */
const TARGETING_AFFECTED_USERS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/targeted-participants`;

/**
 * @const {string} USER_CONTENT_HUB_PROFILE_PATH the endpoint used to check targeting's affected users
 */
const USER_CONTENT_HUB_PROFILE_PATH = '/content-hub/{{eventId}}/participants/view/{{userId}}';


/**
 * @const {string} DELETE_USER_ENDPOINT the endpoint used to delete a user
 */
const DELETE_USER_ENDPOINT = `${API_BASE_PATH}/users/{{email}}`;

/**
 * Provides utils for getting or saving information of a user.
 *
 * @example
 * import UserService from 'libs/services/user';
 * ...
 * const userService = new UserService(user, eventId);
 *
 * @mermaid
 *  graph TD;
 *      BaseService-->SessionService-->UserService;
 */
export default class UserService extends SessionService {
    /**
     * Builds a new UserService instance.
     *
     * @param {Object} [currentUser=null]
     * @param {String} [currentEventId=null]
     */
    constructor(currentUser = null, currentEventId = null) {
        super();
        this.init(currentUser, currentEventId);
    }

    /**
     * Initialize the service
     *
     * @param {Object} currentUser
     *
     * @param {String} [currentEventId=null]
     */
    init(currentUser, currentEventId = null) {
        this.user = currentUser;
        this.eventId = currentEventId;
    }

    /**
     * Initializes the service starting from the current session
     *
     * @param {string} [eventId] the current event ID
     *
     * @returns {Promise<object>} the user object
     */
    async getUserFromSessionAndInitialize(eventId) {
        this.eventId = eventId;
        const { data: session } = await this.getSession();
        const { data: user } = await this.getUser(session.name);
        this.init(user, eventId);
        return user;
    }

    /**
     * Determine if the current user has a permission given a context
     * @param  {string} permission name of the permission for which we want to know if the user has access
     * @param  {Object} context context determining if the user has permission
     * @param  {string} [context.event_id] context attribute event_id if in the context of an event
     * @param  {string} [context.org_id] context attribute org_id if in the context of an org
     * @return {Boolean} true if the user has a role enabling the permission and the role is either a global role or
     * every attribute required for the role are both present in the attributed role and the context and are all equal
     * false otherwise
     */
    hasPermission(permission, context = null) {
        return hasPermission(
            this.user.roles,
            permission,
            context
        );
    }

    /**
     * Check if current user matches one of the provided conditions (OR), which can be one of those.
     * Several conditions can be put into one to match several conditions at the same time.
     * No condition will return true
     *
     * `{ userId: id }` matches if userId is id
     * `{ isSpotme: true}` matches if user belongs to spotme org
     * `{ globalRole: role }`
     * `{ eventRole: role, [eventId: eid] }` eventId is optional, if not provided, current event is used
     * `{ eventId: eid }` has any role in given event
     * `{ orgRole: role, orgId: orgId }`
     * `{ orgId: orgId }` has any role in given organisation
     * `{ orgRole: role }` has role in any organisation
     *
     * @param {...Object} roles
     *
     * @returns {Boolean}
     */
    matchesRoles(...roles) {
        return matchesRoles(this.eventId, this.user, ...roles);
    }

    /**
     * Determine if the current user can attribute assign a different role
     * @param  {Object}  userRoles the supported user roles
     * @param  {Object}  requiredRole the required role
     * @param  {string}  [requiredRole.role] name of the role
     * @param  {string}  [requiredRole.event_id] context attribute event_id if in the context of an event
     * @param  {string}  [requiredRole.org_id] context attribute org_id if in the context of an org
     * @return {Boolean} true if the user has a role enabling the permission and the role is either a global role or
     * every attribute required for the role are both present in the attributed role and the context and are all equal
     * false otherwise
     */
    canAssignRole(userRoles, requiredRole) {
        return canAssignRole(userRoles, requiredRole);
    }

    /**
     * Given a user email this method toggles a global role
     *
     * @param {String} username the user email
     * @param {String} role global role name
     *
     * @return {Promise} the server response
     */
    toggleUserGlobalPermission(username, role) {
        return this.toggleUserPermission(username, role, 'toggle-global-permission');
    }

    /**
     * Toggles the specified organization permission for the given user.
     *
     * @param {String} username the user email
     * @param {String} access the access level to toggle
     * @param {String} subpath the subpath related to the permission
     *
     * @returns {Promise} the server response
     */
    toggleUserPermission(username, access, subpath) {
        const descriptor = { access };
        const url = USER_SERVICE_ENDPOINT
            .replace('{{username}}', username)
            .replace('{{path}}', subpath);

        return this.post(url, descriptor, { withCredentials: true });
    }

    /**
     * Returns user object with given username
     * Available only with superadmin permissions or
     * with username belonging to currently logged user
     *
     * @param {String} username
     * @param {Boolean} [noEvents=true] fetches also user's events if set to `false`
     *
     * @returns {Promise} the server response
     */
    async getUser(username, noEvents = true) {
        const url = this.buildUrl(USER_GET_ENDPOINT, { username, noEvents, eid: this.eventId });
        return this.get(url);
    }

    /**
     * Returns the current user.
     *
     * @returns {Object} the current user.
     */
    getCurrentUser() {
        return this.user;
    }

    /**
     * Gets the events list of the given user.
     * If no username is provided then the current user is used.
     *
     * @param {String} [username=current_user]
     *
     * @returns {Promise} the server response
     */
    async getUserEvents(username = this.user.name) {
        const url = USER_GET_ENDPOINT_ALL_EVENTS.replace('{{username}}', username);
        const { data } = await this.get(url);

        return data.events;
    }

    /**
     * Gets the webinars list of the current user.
     *
     * @returns {Promise} the server response
     */
    async getUserWebinars() {
        const { data } = await this.get(USER_GET_ENDPOINT_ALL_WEBINARS);
        return data;
    }

    /**
     * Check if current user has webinars enabled organizations
     *
     * @return {Boolean} if true the user can create webinars
     */
    canCreateWebinars() {
        return !!this.getOrgs().find(org =>
            org.show_studio_express && this.hasPermission('global_actions.create_webinar', {
                org_id: org._id
            })
        );
    }

    /**
     * Returns the user events or webinars, grouped by future, live, past events and all events
     *
     * @param {String[]} [latest] an optional list of last accessed event IDs
     * @param {'event'|'webinar'|'all'} type one of 'event'|'webinar'|'all'
     * @param {*} filtering function to exlude workspace based upon specific charateristics
     * @returns {Promise<Object>} Promise with object of the grouped events
     */
    async getUserEventsGrouped(latest = [], type = 'event', filtering = () => { return true; }) {
        let events = [], start, end;

        if (type !== 'event') {
            events.push (...await this.getUserWebinars());
        }
        if (type === 'webinar') {
            start = startOfToday();
            end = endOfToday();
        }

        if (type !== 'webinar') {
            events.push(...await this.getUserEvents());
        }

        events = events.filter(event => filtering(event));
        return groupByStage(events, latest, start, end);
    }

    /**
     * Returns an array of all users global role names
     *
     * @param {Object} user
     *
     * @return {Array<String>}
     */
    getUsersGlobalRoles(user) {
        return user.roles
            .filter(role => role.type === 'global_permission')
            .map(role => role.role);
    }

    /**
     * Filter array of items by matching the user roles
     * Array items not defining `roles` property will stay in the result array.
     *
     * @param {object[]} items
     *
     * @returns {Array} items filtered according to roles of current user
     */
    filterByRoles(items) {
        return items.filter(item => !item.roles || this.matchesRoles(item.roles));
    }

    /**
     * Retrieves the organizations associated with the user.
     *
     * @returns {Array} An array of organizations.
     */
    getOrgs() {
        return this.user?.orgs || [];
    }

    /**
     * Given roles, this method returns all the organizations the user has these roles.
     *
     * @param {string[]} roles the user roles to match the organization role with.
     *
     * @return {object[]} a list of organizations
     */
    getOrgsByRoles(roles) {
        return this.getOrgs().filter(o => o.roles.some(role => roles.includes(role)));
    }

    /**
     * Retrieves the managed organizations for the user.
     *
     * @returns {Array<string>} A promise that resolves to an array of organization names.
     */
    getManagedOrgs() {
        return this.getOrgsByRoles(['admin', 'organizer', 'member']);
    }

    /**
     * Check if the current user matches given username
     *
     * @param {String} username
     *
     * @return {Boolean}
     */
    isCurrentUser(username) {
        return this.user.name === username;
    }

    /**
     * Checks if the current user is admin of one of the organizations he belongs
     *
     * @returns {Boolean} if user is admin in an org
     */
    isAdmin() {
        return this.getOrgs().some(org => this.matchesRoles({ orgRole: ROLE_ADMIN, orgId: org._id }));
    }

    /**
     * Checks if the current user can read the organization document
     *
     * @param {String} org_id the organization id
     *
     * @returns {Boolean} if user can read the organization doc
     */
    canReadOrgDoc(org_id) {
        return this.hasPermission('org_realms.read_org_doc', { org_id });
    }

    /**
     * Checks if the current user is an API developer in some org
     *
     * @returns {Boolean} whether the user is an API developer or not
     */
    isApiDeveloper() {
        return this.getOrgs().some(
            org => this.hasPermission('org_realms.public_api', {
                org_id: org._id
            })
        );
    }

    /**
     * Checks if the user belongs to the specified organization.
     *
     * @param {String} orgId the ID of the organization to check
     *
     * @returns {Boolean} true if the user belongs the organization.
     */
    isPartOfOrg(orgId) {
        return !!this.getOrgs().find(org => org._id === orgId);
    }

    /**
     * Return full path for users profile in given event
     *
     * @param {String} eventNodeUrl cloud node URL
     * @param {String} eventId event ID
     * @param {String} userId user ID
     * @param {Boolean} [isContentHub=false]
     *
     * @returns {String} user'sprofile URL
     */
    getProfileUrl(eventNodeUrl, eventId, userId, isContentHub = false) {
        let path = isContentHub ? USER_CONTENT_HUB_PROFILE_PATH : USER_PROFILE_PATH;
        path = path
            .replace('{{eventId}}', eventId)
            .replace('{{userId}}', userId);

        return getBackstageURL(getNodeName(eventNodeUrl), path);
    }

    /**
     * Is user event moderator?
     *
     * @param {String} eventId event ID
     * @param {Object} [user=this.user] user object or current user if not provided
     * @returns {Boolean}
     */
    isEventModerator(eventId, user = this.user) {
        const roles = this.getEventRoles(eventId, user);

        return roles.includes('moderator');
    }

    /**
     * Is user event speaker?
     *
     * @param {String} eventId event ID
     * @param {Object} [user=this.user] user object or current user if not provided
     * @returns {Boolean}
     */
    isEventSpeaker(eventId, user = this.user) {
        const roles = this.getEventRoles(eventId, user);

        return roles.length === 1 && roles.includes('speaker');
    }

    /**
     * Extracts the roles of a specific user in an event
     *
     * @param {String} eventId event ID
     * @param {Object} [user=this.user] user object or current user if not provided
     * @returns {String[]} the list of roles
     */
    getEventRoles(eventId, user = this.user) {
        const roles = user.roles || [];
        return uniq(roles
            .filter(r => r.type === 'event_permission' && r.event_id === eventId)
            .map(r => r.role)
        );
    }

    /**
     * Check if user was invited by link to the platform
     * Used for users accessing the event through accredited links
     *
     * @param {Object} event event object
     * @returns {Boolean}
     */
    isInvitedByLink(event) {
        if (!event) {
            return false;
        }
        const context = {
            event_id: event._id,
            org_id: event.ownerId
        };
        return !this.hasPermission('node_crud_realms.read_event_data', context);
    }

    /**
     * Checks if the user has permission to manage streams.
     *
     * @param {string} eventId the ID of the event
     * @param {string} orgId the ID of the organization
     *
     * @returns {boolean} true if the user can manage live streams
     */
    canManageStreams(eventId, orgId) {
        const permissionContext = {
            event_id: eventId,
            org_id: orgId
        };

        return this.hasPermission('live_stream.manage_stream', permissionContext);
    }

    /**
     * Checks if the user has permission to manage a Studio session.
     *
     * @param {string} eventId the ID of the event
     * @param {string} orgId the ID of the organization
     *
     * @returns {boolean} true if the user can manage a Studio session
     */
    canManageStudioSession(eventId, orgId) {
        const permissionContext = {
            event_id: eventId,
            org_id: orgId
        };

        return this.hasPermission('live_stream.studio.manage_session', permissionContext);
    }

    /**
     * Checks if the current user is a super admin
     *
     * @returns {Boolean} whether the user is a super admin or not
     */
    isSuperAdmin() {
        return this.matchesRoles([
            { globalRole: ROLE_SUPERADMIN }
        ]);
    }

    /**
     * Checks if the current user is a beta tester
     *
     * @returns {Boolean} whether the user is a beta tester or not
     */
    isBetaTester() {
        return this.matchesRoles([
            { globalRole: ROLE_BETA_TESTER }
        ]);
    }

    /**
     * Checks if the current user is a developer
     *
     * @returns {Boolean} whether the user is a developer or not
     */
    isDeveloper() {
        return this.matchesRoles([
            { globalRole: ROLE_DEVELOPER }
        ]);
    }

    /**
     * fetches webapp link for current user
     *
     * @param {string} eventId the ID of the event
     * @param {string} [redirect] a redirect url
     *
     * @return {Promise<Object>}
     */
    async getWebappLoginUrl(eventId, redirect = null) {

        const url = WEP_APP_LOGIN_URL_ENDPOINT
            .replace('{{eventId}}', eventId);

        const { data } = await this.post(url, { redirect });
        return data.url;
    }

    /**
     * Given a feature flag, this method returns all the current
     * user's organizations that present that flag.
     *
     * @param {string} flag the name of the feature to check
     *
     * @return {object[]} a list of organizations
     */
    getOrgsByFeatureFlag(flag) {
        return getOrgsByFeatureFlag(this.getOrgs(), flag);
    }

    /** @type {() => object[]} */
    getOrgsUserIsAllowedToCreateContentHubFor() {
        return this.getOrgs().filter(org =>
            org.content_hubs_enabled
            && this.hasPermission(
                'global_actions.create_content_hub',
                { org_id: org._id }
            )
        );
    }

    /**
     * Given a targeting configuration this method returns
     * the list of event's affected users
     *
     * @param {string} eventId the ID of the event
     * @param { { targets: object[], exceptions: object[] }} targeting the targeting object
     * @param {AbortSignal} [signal] an optional interface to abort the request
     *
     * @return {Promise<{ id: string, fname: string, lname: string }[]>} a list of affected users
     */
    async getAffectedUsersPreview(eventId, targeting, signal) {
        const url = this.buildUrl(TARGETING_AFFECTED_USERS_ENDPOINT, { eventId });
        const { data } = await this.post(url, { ...targeting }, { signal });
        return data;
    }

    /**
     * Checks if the current user can create new backstage users in the given org
     *
     * @param {String} orgId the ID of the organization
     * @returns {Boolean} if user can create new users
     */
    canCreateBackstageUsers(orgId) {
        return this.hasPermission('global_actions.create_user', { org_id: orgId });
    }

    /**
     * Permanently delete a user
     *
     * @param {string} email
     */
    deleteUserByEmail(email) {
        const url = this.buildUrl(DELETE_USER_ENDPOINT, { email });
        return this.delete(url);
    }
}
