{
    "dashboard": {
        "name": "dashboard",
        "label": "main_menu.categories.dashboard",
        "modules": ["dashboard"],
        "icon": "ic-dashboard",
        "additional_types": [],
        "order": []
    },
    "design": {
        "name": "design",
        "label": "main_menu.categories.design",
        "modules": ["home-editor", "navspotman", "theme-editor", "emailing"],
        "order": ["home-editor", "navspotman", "theme-editor", "emailing"],
        "icon": "ic-palette",
        "additional_types": []
    },
    "content": {
        "name": "content",
        "label": "main_menu.categories.content",
        "modules": [
            "participants",
            "registration",
            "presenter",
            "sessions",
            "documents",
            "content-pages",
            "pkg-forms",
            "files",
            "maps",
            "shortlinks",
            "abstracts",
            "sponsors",
            "video-library"
        ],
        "order": [
            "participants",
            "registration",
            "presenter",
            "sessions",
            "content-pages",
            "pkg-forms"
        ],
        "icon": "ic-folder",
        "additional_types": []
    },
    "onsite_logistic": {
        "name": "onsite_logistic",
        "label": "main_menu.categories.onsite_logistic",
        "modules": [
            "badge-design",
            "onsite-scanning",
            "session-check-in",
            "leads",
            "assets-library"
        ],
        "order": [
            "onsite-scanning",
            "session-check-in",
            "badge-design",
            "leads"
        ],
        "icon": "ic-qr-code",
        "additional_types": []
    },
    "live": {
        "name": "live",
        "label": "main_menu.categories.live",
        "modules": [
            "feed",
            "messages",
            "activity-challenge",
            "augmented-reality-challenge",
            "ar-scan",
            "badger",
            "brainstorming",
            "connectionwallSpotlet",
            "panel-discussion",
            "voting",
            "poster-challenge",
            "matchmaking",
            "qnaSpotlet",
            "rate-sessions",
            "votingSpotlet",
            "live-polling",
            "game-configurations",
            "ar-quiz",
            "participant_matchmaking_configs",
            "ratings-dashboard",
            "live-voting",
            "qna_manager",
            "leaderboard",
            "prize-store",
            "meetings",
            "game-store"
        ],
        "order": ["feed", "messages", "meetings"],
        "icon": "ic-star-outlined",
        "additional_types": []
    },
    "analytics": {
        "name": "analytics",
        "label": "main_menu.categories.analytics",
        "modules": ["analytics"],
        "icon": "icon-line-graph",
        "explode": true,
        "additional_types": [],
        "order": [],
        "visibility": "showLegacyAnalytics"
    },
    "integrations": {
        "name": "integrations",
        "label": "main_menu.categories.integrations",
        "modules": [
            "api-data",
            "data-api-cvent",
            "data-api-eventcore",
            "data-api-aventri",
            "data-api-braindate",
            "data-api-hubb",
            "data-api-lanyon",
            "data-api-netkin"
        ],
        "icon": "icon-power-plug",
        "additional_types": [],
        "order": []
    },
    "dev": {
        "name": "dev",
        "label": "main_menu.categories.dev",
        "modules": ["document-editor"],
        "icon": "ic-database",
        "roles": [
            { "globalRole": "developer" }
        ],
        "explode": true,
        "additional_types": [],
        "order": []
    },
    "other": {
        "name": "other",
        "label": "main_menu.categories.other",
        "modules": ["support"],
        "icon": "icon-ellipsis-horizontal",
        "additional_types": [],
        "order": []
    },
    "marketplace": {
        "name": "marketplace",
        "label": "main_menu.categories.marketplace",
        "modules": ["marketplace"],
        "icon": "ic-store",
        "additional_types": [],
        "explode": true,
        "order": ["all", "content_and_logistics", "integration", "live", "networking"]
    },
    "settings": {
        "name": "settings",
        "label": "main_menu.categories.settings",
        "modules": ["notes", "spotlet", "i18n-editor"],
        "icon": "ic-gear",
        "additional_types": ["setting"],
        "setting": [],
        "order": []
    }
}
